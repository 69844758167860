import * as React from 'react';

import styled from '@emotion/styled';
import Container from '../components/Container';
import Page from '../components/Page';
import IndexLayout from '../layouts';
import { TrendsTable } from '../components/TrendsTable';
import { abbreviateNumber, addCommas } from '../utils/numbers';
import { PageHeader } from '../components/PageHeader';

interface Props {
  pageContext: any;
}

const StyledParagraphBetweenTables = styled.p`
  margin-top: -20px;
`;

function TopStockTickersText({ subredditName = '', subscribers = 0, days = 1 }) {
  const hours = days * 24;

  if (subredditName === 'wallstreetbetsOGs') {
    return (
      <>
        Top stock tickers popular on private wallstreetbets community, of{' '}
        <span title={addCommas(subscribers)}>{abbreviateNumber(subscribers)}</span> members, over the last {hours}-hours.
      </>
    );
  }

  return (
    <>
      Top stock tickers popular on the Reddit <a href={`https://www.reddit.com/r/${subredditName}`}>{subredditName}</a> community, of{' '}
      <span title={addCommas(subscribers)}>{abbreviateNumber(subscribers)}</span> members, over the last {hours}-hours.
    </>
  );
}

export default function WSBsTemplate({ pageContext: { wsb, wsbOG, wsbElite, wsbNew, sbs, renderTime } }: Props) {
  return (
    <IndexLayout>
      <Page>
        <Container>
          <PageHeader time={renderTime} />
          <h1>Reddit WSBs Trends</h1>
          <p>These are the trends for the four wallstreetbets (WSB) subreddits on Reddit and the smallstreetbets subreddit.</p>
          <p>
            <TopStockTickersText subredditName="wallstreetbets" subscribers={wsb.totalSubscribers} days={wsb.days} />
          </p>
          <TrendsTable data={wsb} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            <TopStockTickersText subredditName="wallstreetbetsOGs" subscribers={wsbOG.totalSubscribers} days={wsbOG.days} />
          </StyledParagraphBetweenTables>
          <TrendsTable data={wsbOG} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            <TopStockTickersText subredditName="Wallstreetbetsnew" subscribers={wsbNew.totalSubscribers} days={wsbNew.days} />
          </StyledParagraphBetweenTables>
          <TrendsTable data={wsbNew} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            <TopStockTickersText subredditName="WallStreetbetsELITE" subscribers={wsbElite.totalSubscribers} days={wsbElite.days} />
          </StyledParagraphBetweenTables>
          <TrendsTable data={wsbElite} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            <TopStockTickersText subredditName="smallstreetbets" subscribers={sbs.totalSubscribers} days={sbs.days} />
          </StyledParagraphBetweenTables>
          <TrendsTable data={sbs} postsInsteadOfComments />
        </Container>
      </Page>
    </IndexLayout>
  );
}
